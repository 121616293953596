import React, { useLayoutEffect, useRef, useState, useCallback } from "react"
import { graphql } from "gatsby"
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { FormattedMessage } from 'react-intl'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroIntro from "../components/hero-intro"
import HeroIntroImage from "../components/hero-intro-image"
import TileList from "../components/tile-list"
import PageFascia from "../components/page-fascia"
import SocialShare from "../components/elements/social-share"
import VideoModal from "../components/video-modal"
import withPreview from "../utility/with-preview"
import pageUrl from "../utility/page-url"
import PlayArrowIcon from '../images/icons/material/play_arrow.svg'


gsap.registerPlugin(ScrollTrigger);

const LongFormTemplate = ({ data: { page, meta }, pageContext: { langCode } }) => {
  const { pageTitle, htmlTitle, intro, section1, relatedTiles } = page;

  const wrapperRef = useRef(null);
  const introRef = useRef(null);

  useLayoutEffect(() => {
    gsap.to(introRef.current, {
      yPercent: -100,
      ease: "none",
      stagger: 0.5,
      scrollTrigger: {
        trigger: wrapperRef.current,
        start: "top top",
        end: "+=100%",
        scrub: true,
        pin: true
      }
    });
    return () => {
      ScrollTrigger.getAll().forEach((instance) => {
        instance.kill();
      });
      // This in case a scroll animation is active while the route is updated
      gsap.killTweensOf(window);
    };
  }, []);

  const canonical = (htmlTitle.content && htmlTitle.content.canonicalUrl && htmlTitle.content.canonicalUrl.value)
    || `${meta.siteUrl}/${pageUrl(page)}`;

  // Gestione eventuale video
  const videoLink = section1[0]?.content?.section1Image?.content?.video?.value;
  const [videoVisible, setVideoVisible] = useState(false);
  const showVideo = useCallback(() => {
    if (videoLink) {
      setVideoVisible(true);
    }
  }, [videoLink]);
  const closeVideo = useCallback(() => setVideoVisible(false), []);

  return (
    <Layout langCode={langCode} tags={page.tags} ref={wrapperRef} >
      <Seo title={htmlTitle.value || pageTitle.value} langCode={langCode} ogImage={htmlTitle.content.ogImage}
        description={htmlTitle.content && htmlTitle.content.metaDescription && htmlTitle.content.metaDescription.value}
        skipEn={pageTitle.content && pageTitle.content.skipEn} canonical={canonical}
        robots={htmlTitle.content && htmlTitle.content.metaRobots && htmlTitle.content.metaRobots.value} />
      <div className="news-detail news-detail--long">
        <div ref={introRef} className="news-detail__intro">
          {intro.__typename === 'LiferayIntroLongForm' ? (
            <HeroIntro data={intro} />
          ) : <HeroIntroImage data={intro} />}
        </div>
        <div className="news-detail__content">
          {section1.map((section, i) => (
            <React.Fragment key={i}>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div className={`news-detail__hero${videoLink ? ' news-detail__hero--video': ''}`} onClick={showVideo}>
                <Img image={getImage(section.content.section1Image.node)} loading="eager"
                  alt={section.content.section1Image.node.description || ''} className="news-detail__hero-img" />
                  {videoLink ? (
                    <div className="intro-simple__play bg-blue-corporate">
                      <PlayArrowIcon className="material-svg text-white" />
                    </div>
                  ): null}
              </div>
              {i === 0 ? <span id="news-detail-content"></span> : null}
              <div className="section section--overlap position-relative">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <SocialShare page={page} className="d-md-none mt-3 text-left" title={page.pageTitle.value} siteUrl={meta.siteUrl} />
                      <div className="section__content pb-5 mb-5 pt-1 pt-md-5"
                        dangerouslySetInnerHTML={{ __html: section.content.section1Text.value }}>
                      </div>
                    </div>
                  </div>
                </div>
                <SocialShare page={page} className="d-none d-md-block" title={page.pageTitle.value} siteUrl={meta.siteUrl} />
              </div>
              {section.content.section1Fasce ? (
                section.content.section1Fasce.map((fascia, i) => <PageFascia key={i} data={fascia} />)
              ) : null}
            </React.Fragment>
          ))}
          {relatedTiles && relatedTiles.length ? (
            <div className="section pb-5 mb-5">
              <div className="container">
                <div className="row mb-3">
                  <div className="col-12">
                    <div className="news-detail__banner-title font-weight-bold">
                      <FormattedMessage id="related-media-intro" />
                    </div>
                  </div>
                </div>
              </div>
              <TileList tiles={relatedTiles} rows={1} related={true} />
            </div>
          ) : null}
        </div>
      </div>
      <VideoModal show={videoVisible} handleClose={closeVideo} video={videoLink} />
    </Layout>
  );
}

export default withPreview(LongFormTemplate, { subField: 'page', fixed: true });
export const query = graphql`query LongFormQuery($liferayId: Int!, $langCode: String!){
  meta: gatsbySourceLiferayOptions {
    siteUrl
  }
  page: liferayLongForm(liferayId: { eq: $liferayId }, langCode: { eq: $langCode}) {
    siteId
    articleId
    langCode
    pageTitle {
      value
      content {
        friendlyUrl {
          value
        }
        skipEn
      }
    }
    htmlTitle {
      value
      content {
        metaDescription {
          value
        }
        canonicalUrl {
          value
        }
      }
      ...LongFormTitleFragment
    }
    intro {
      __typename
      ...HeroIntroFragment
      ...HeroIntroImageFragment
    }
    section1 {
      content {
        section1Image {
          node {
            description
            gatsbyImageData(width: 1440)
          }
          content {
            video {
              value
            }
          }
        }
        section1Text {
          value
        }
        section1Fasce {
          __typename
          ...ColumnsFragment
          ...IntrosimpleFragment
          ...ImmaginenumeroFragment
          ...BannerinformativoFragment
          ...BannercitazioneFragment
          ...BannervideoFragment
          ...AllegatiFragment
          ...TileslancioFragment
          ...ProdottoinformativoFragment
          ...ArticoloCorrelatoFragment
          ...IntrodatiFragment
          ...AccordionsFragment
          ...ProdotticorrelatiFragment
          ...ChoiceselectorFragment
          ...BoxnumeriFragment
          ...ManagementTeamFragment
          ...GalleryFragment
        }
      }
    }
    relatedTiles {
      ...TileFragment
    }
    tags: taxonomyCategoryBriefs {
      taxonomyCategoryId
      taxonomyCategoryName
    }
  }
}`
