import React from 'react'
import {  graphql } from "gatsby"
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Link as ScrollLink } from 'react-scroll'
import withPreview from '../utility/with-preview'
import ArrowDropDownIcon from '../images/icons/material/arrow_drop_down.svg'
import QueryBuilderIcon from '../images/icons/material/query_builder.svg'


const HeroIntroImage = ({ data: { mainImage, mainTitle } }) => {
  return (
    <div className="hero-intro-image d-flex flex-column">
      <Img image={getImage(mainImage.node)} alt={mainImage.node.description || ''} className="hero-intro-image__img" />
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-12 h-100 d-flex flex-column">
            <div className="hero-intro-image__content mt-4 mt-xl-0 d-flex flex-column justify-content-center 
            align-items-center align-items-lg-start">
              <h1 className="hero-intro-image__title d-inline-block text-center text-lg-left"
                dangerouslySetInnerHTML={{__html: mainTitle.value }}>
              </h1>
              {mainTitle.content && mainTitle.content.mainSubtitle && mainTitle.content.mainSubtitle.value ? (
                <div className="hero-intro-image__subtitle d-none d-md-inline-block"
                  dangerouslySetInnerHTML={{ __html: mainTitle.content.mainSubtitle.value }}></div>
              ): null}
            </div>
            <div className="hero-intro-image__actions mt-auto d-flex justify-content-center align-items-center">
              <ScrollLink to="news-detail-content" smooth={true} 
                className="nj-btn nj-btn--primary nj-btn--lg align-self-center">
                <span className="ml-2 text-white">Leggi</span>
                <ArrowDropDownIcon className="material-svg text-white ml-2" />
              </ScrollLink>
              <QueryBuilderIcon className="material-svg ml-4" />
              {mainTitle.content && mainTitle.content.readMinutes && mainTitle.content.readMinutes.value && (
                <span className="hero-intro-image__time font-weight-bold ml-1">
                  {mainTitle.content.readMinutes.value}'
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withPreview(HeroIntroImage);
export const tileFragment = graphql`fragment HeroIntroImageFragment on LiferayIntroLongFormLaterale{
  siteId
  articleId
  mainImage {
    node {
      description
      gatsbyImageData(width: 1440)
    }
  }
  mainTitle {
    value
    content {
      mainSubtitle {
        value
      }
      readMinutes {
        value
      }
    }
  }
}
`