import React, { useMemo, useState, useCallback } from "react"
import { CopyToClipboard } from 'react-copy-to-clipboard'
import pageUrl from "../../utility/page-url"

import Facebook from "../../images/icons/facebook.svg"
import Whatsapp from "../../images/icons/whatsapp.svg"
import Twitter from "../../images/icons/twitter.svg"
import Linkedin from "../../images/icons/linkedin.svg"

import ContentCopyIcon from "../../images/icons/material/content_copy.svg"


const SocialShare = ({ page, title, siteUrl, className = '' }) => {
  const url = useMemo(() => {
    const path = pageUrl(page);
    return `${siteUrl}/${path.startsWith('/') ? path.substring(1) : path}`;
  }, [siteUrl, page]);
  const encodedUrl = encodeURIComponent(url);

  const twitterMessage = useMemo(() => encodeURIComponent(`${title ? `${(title)} | `: ''}${url} via @engieitalia`),
    [title, url]);

  const [copied, setCopied] = useState(false);
  const onCopy = useCallback(() => setCopied(true), []);

  return (
    <div className={`news-detail__social ${className}`}>
      <a href={`https://www.linkedin.com/cws/share?url=${encodedUrl}`} 
        target="_blank" className="news-detail__social-ln mb-1" rel="noopener noreferrer">
        <Linkedin />
      </a>
      <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}${title ? `&t=${encodeURIComponent(title)}`: ''}`} 
        target="_blank" className="news-detail__social-fb mb-1" rel="noopener noreferrer">
        <Facebook />
      </a>
      <a href={`https://twitter.com/intent/tweet?text=${twitterMessage}`} target="_blank" 
        className="news-detail__social-tw mb-1" rel="noopener noreferrer">
        <Twitter />
      </a>
      <a href={`https://wa.me/?text=${encodedUrl}`} target="_blank" 
        className="news-detail__social-wa mb-1" rel="noopener noreferrer">
        <Whatsapp />
      </a>
      <CopyToClipboard text={url} onCopy={onCopy}>
        <span title={url} className={`news-detail__social-cp${copied ? ' news-detail__social-cp--copied':''} d-inline-flex justify-content-center align-items-center`}>
          <ContentCopyIcon className="material-svg text-white" />
        </span>
      </CopyToClipboard>
    </div>
  );
}

export default SocialShare;


