import React, { useMemo } from 'react'
import { graphql } from "gatsby"
import { GatsbyImage as Img, getImage, withArtDirection } from 'gatsby-plugin-image'
import { Link as ScrollLink } from 'react-scroll'
import withPreview from '../utility/with-preview'
import SvgIcon from './elements/svg-icon'
import ArrowDropDownIcon from '../images/icons/material/arrow_drop_down.svg'
import QueryBuilderIcon from '../images/icons/material/query_builder.svg'


const HeroIntro = ({ data: { imageDesktop, imageTablet, imageMobile, mainTitle, leftTip, rightTip } }) => {
  const sources = useMemo(() => {
    return imageMobile || imageTablet ? withArtDirection(getImage(imageDesktop.node), []
    .concat(
      imageTablet ? {
        media: "(max-width: 992px)",
        image: getImage(imageTablet.node),
      }: []
    ).concat(
      imageMobile ? {
        media: "(max-width: 576px)",
        image: getImage(imageMobile.node),
      }: []
    )): getImage(imageDesktop.node);
  }, [imageMobile, imageTablet, imageDesktop]);

  return (
    <div className={`hero-intro${leftTip && leftTip.value ? ' hero-intro--tips': ''} d-flex justify-content-around`}>
      <Img image={sources} alt={imageDesktop.node.description || ''} className="hero-intro__img" />
      {leftTip && leftTip.value ? (
        <div className="hero-intro__tip hero-intro__tip--left flex-column">
          {/*<div className="text-uppercase">Tip</div>*/}
          {leftTip.content && leftTip.content.leftIcon && leftTip.content.leftIcon.value ? (
            <SvgIcon icon={leftTip.content.leftIcon.value} className="hero-intro__icon mt-auto" />
          ): null}
          <div dangerouslySetInnerHTML={{__html: leftTip.value }}></div>
        </div>
      ): null }
      <div className="hero-intro__center d-flex flex-column">
        <div className="hero-intro__content d-flex flex-column justify-content-center align-items-center text-center">
          <h1 className="hero-intro__title text-white" dangerouslySetInnerHTML={{__html: mainTitle.value }}></h1>
          {mainTitle.content && mainTitle.content.mainSubtitle && mainTitle.content.mainSubtitle.value ? (
            <div className="hero-intro__subtitle text-white" 
              dangerouslySetInnerHTML={{__html: mainTitle.content.mainSubtitle.value}}></div>
          ): null}
        </div>
        <div className="hero-intro__actions mt-auto d-flex justify-content-center align-items-center">
          <ScrollLink to="news-detail-content" smooth={true} 
            className="nj-btn nj-btn--primary nj-btn--lg align-self-center">
            <span className="ml-2 text-white">Leggi</span>
            <ArrowDropDownIcon className="material-svg text-white ml-2" />
          </ScrollLink>
          <QueryBuilderIcon className="material-svg text-white ml-4" />
          {mainTitle.content && mainTitle.content.readMinutes && mainTitle.content.readMinutes.value && (
            <span className="hero-intro__time text-white font-weight-bold ml-1">
              {mainTitle.content.readMinutes.value}'
            </span>
          )}
        </div>
      </div>
      {rightTip && rightTip.value ? (
        <div className="hero-intro__tip hero-intro__tip--right flex-column justify-content-center mt-auto">
          {rightTip.content && rightTip.content.rightIcon && rightTip.content.rightIcon.value ? (
            <SvgIcon icon={rightTip.content.rightIcon.value} className="hero-intro__icon" />
          ): null}
          <div dangerouslySetInnerHTML={{ __html: rightTip.value }}></div>
        </div>
      ): null}
    </div>
  );
}

export default withPreview(HeroIntro);
export const tileFragment = graphql`fragment HeroIntroFragment on LiferayIntroLongForm{
  siteId
  articleId
  imageDesktop {
    node {
      description
      gatsbyImageData(width: 1440)
    }
  }
  imageTablet {
    node {
      gatsbyImageData(width: 992)
    }
  }
  imageMobile {
    node {
      gatsbyImageData(width: 576)
    }
  }
  mainTitle {
    value
    content {
      mainSubtitle {
        value
      }
      readMinutes {
        value
      }
    }
  }
  leftTip {
    value
    content {
      leftIcon {
        value
      }
    }
  }
  rightTip {
    value
    content {
      rightIcon {
        value
      }
    }
  }
}
`